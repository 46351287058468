.react-datepicker__day--selected, .react-datepicker__day--selected:hover
  background-color: #7d9e1d

.react-datepicker
  border: none
  box-shadow: 0px 6px 20px 0px #0000001a

.react-datepicker__header
  border-bottom: none

.react-datepicker__triangle
  display: none

.react-datepicker__start-date-input
  text-align: left
  font-weight: bold
  border-top-right-radius: 0
  border-bottom-right-radius: 0

.react-datepicker__end-date-input
  text-align: left
  font-weight: bold
  border-top-left-radius: 0
  border-bottom-left-radius: 0
