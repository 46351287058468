.account-page
  .account-page__overlay
    overflow: hidden
    position: fixed
    margin-left: -100%
    top: 0
    left: 0
    right: 0
    transition: 0.2s
    width: 100%
    z-index: 100

  .account-page__overlay--open
    margin-left: 0%

  .account-page__content
    background-color: $white-lilac
    height: 100vh

  .account-page__hero-block
    position: relative

  .account-page__hero-image
    width: 100%

  .account-page__hero-content
    align-items: flex-end
    bottom: 0
    display: flex
    left: 0
    padding: 1rem
    position: absolute
    right: 0
    top: 0

  .account-page__hero-title
    color: $white
    font-family: $font-family-geomanistbook
    font-size: 1.5rem

  .account-page__user-block
    align-items: flex-end
    display: flex
    height: 64px
    padding: 0.5rem 1.5rem

  .account-page__user-info-content
    margin-top: 0.5rem

  .account-page__user-info-title
    color: #DBDBDB
    font-family: $font-family-proximasoft
    font-size: 0.875rem
    line-height: 1.125rem

  .account-page__user-info
    color: $white
    font-family: $font-family-proximasoft
    font-size: 17px
    line-height: 21px
    display: flex
    align-items: center

  .account-page__user-info-name
    font-family: $font-family-geomanistbook
    margin-right: 8px

  .account-page__items
    list-style: none
    padding: 0

  .account-page__item
    align-items: center
    background-color: $white
    box-shadow: inset 0px -1px 0px $gainsboro
    display: flex
    font-size: 1rem
    height: 56px

    a
      color: $gun-powder
      padding: 1rem 1.5rem
      width: 100%

      &:hover,
      &:focus
        outline: none
        text-decoration: none
