.alert-danger
  background-color: $bizarre
  color: $gun-powder

.caret-off::before
  display: none

.caret-off::after
  display: none

.caret-white::after
  border-top-color: white
  border-bottom-color: white
