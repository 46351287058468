.subsidy
  border-top: 1px solid #EAEAEB
  background: #F4F5F5

.subsidy__row
  border-top: none
  padding: 1rem 1.5rem
  border-top-left-radius: 0 !important
  border-top-right-radius: 0 !important
  border-bottom-right-radius: 0.25rem
  border-bottom-left-radius: 0.25rem
  border-top-left-radius: 0.25rem
  border-bottom-left-radius: 0.25rem
  border-bottom: 1px solid $white-lilac

.subsidy__amount
  font-size: 16px
  padding-bottom: 10px

.subsidy__subtext
  color: $grey-chateau

.subsidy__col
  padding-bottom: 16px

.subsidy__col--border
  border-left: 1px solid #EAEAEB
