.pagination
  display: flex
  flex-wrap: wrap
  justify-content: center

  > span
    padding: 0.3rem 0.8rem
    background: $white
    color: $lima
    border-left: 1px solid $gainsboro
    margin-bottom: 1rem

    &:first-child
      border-radius: 4px 0 0 4px
      border-left: none

    &:last-child
      border-radius: 0 4px 4px 0

  > .first, > .prev, > .next, > .last
    position: relative
    a
      opacity: 0
      position: absolute

  > .first
    &:after
      content: 'First'

  > .prev
    &:after
      content: 'Prev'

  > .next
    &:after
      content: 'Next'

  > .last
    &:after
      content: 'Last'

  .page.current
    background: $lima
    color: $white

