.cc-option-container
  border-bottom: 1px solid #eaeaeb
  margin-bottom: 16px

.cc-option
  display: flex
  font-size: 14px
  margin-bottom: 16px

.cc-option--with-border-bottom
  border-bottom: 1px solid $white-lilac
  padding-bottom: 16px

.cc-option--with-right-actions
  align-items: center

.cc-option__icon,
.cc-option__input
  align-items: center
  display: flex
  margin-right: 16px

.cc-option__name
  font-weight: bold

.cc-option__label
  display: flex
  margin-bottom: 0

.cc-option__details
  color: #515154

.cc-option__actions
  align-items: center
  display: flex
  margin-left: auto

.cc-option__default-payment,
.cc-option__non-default-payment
  margin-right: 16px

.cc-option__default-payment
  align-items: center
  color: $ghost
  display: flex

.cc-option__default-payment-icon
  font-size: 16px
  margin-right: 8px
