.responsive-actions
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  margin-right: -1rem

  .btn
    flex: 1
    margin-bottom: 1rem
    margin-right: 1rem

@media (min-width: $screen-sm)
  .responsive-actions
    justify-content: flex-end

    .btn
      flex: 0
