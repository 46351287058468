.responsive-mobile-modal
  +responsive_modal

  .modal-body
    overflow: auto

  .--show-on-mobile
    display: display

  .--hide-on-mobile
    display: none

  .modal-responsive
    height: 100%
    width: 100%

    .modal-content
      height: 100%

  .modal-mobile-header
    background-color: $white
    display: block

  @media (min-width: $screen-sm)
    .modal-mobile-header
      display: none !important

    .--show-on-mobile
      display: none

    .--hide-on-mobile
      display: block
