.alert
  box-sizing: border-box
  border-radius: 4px
  font-family: $font-family-proximasoft
  font-weight: 400
  color: $gun-powder

.alert-success
  background: rgba(125, 158, 29, 0.24)
  border: 1px solid rgba(0, 0, 0, 0.08)

.alert-danger
  background: $bizarre
  border: 1px solid rgba(0, 0, 0, 0.08)
