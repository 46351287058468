.badges
  display: flex
  flex-direction: row
  flex-wrap: wrap
  margin-left: -5px

.badges__icon
  margin-left: 5px
  margin-top: 5px
  &.transform
    > svg
      --tw-scale-x: 0.75
      --tw-scale-y: 0.75
      transform-origin: center left
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
