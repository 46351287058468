.responsive-table__thead
  display: none

.responsive-table__tr
  border-bottom: 1px solid $gainsboro
  display: flex
  flex-direction: column
  padding: 1rem 0
  position: relative
  max-height: 168px
  flex-wrap: wrap

.responsive-table__td
  border: none !important
  display: inline-block
  flex: 1
  width: calc(100% - 80px)

  &:not(:empty)
    padding: 5px 0

  &:empty
    padding: 0

.responsive-table__td--right
  flex: 4
  width: auto

@media (min-width: $screen-md)
  .responsive-table
    display: table

  .responsive-table__thead
    display: table-header-group

  .responsive-table__tr
    display: table-row

  .responsive-table__td
    display: table-cell

    &:not(:empty)
      padding: 10px 0

  .responsive-table__td--right
    position: static
