.meal-banner
  position: relative
  border: none

  &::before
    content: ''
    background-color: $grey-chateau
    display: block
    position: absolute
    left: 0
    height: 100%
    top: 0
    width: 5px
    border-radius: 4px 0 0 4px

  .meal-banner__body
    display: flex
    flex-direction: column
    padding: 0

  .meal-banner__image
    margin: 0
    width: 300px

    svg
      width: 50%

  .meal-banner__lead
    margin: 0

  .meal-banner__content
    padding: 20px

  .meal-banner__actions
    margin-top: 10px
    margin-bottom: -10px

  .meal-banner__button
    margin-bottom: 10px
    margin-right: 1rem
    width: 100%

  @media (min-width: $screen-xxs)
    .meal-banner__image
      svg
        width: unset

    .meal-banner__button
      width: unset

  @media (min-width: $screen-md)
    .meal-banner__body
      flex-direction: row-reverse
      justify-content: space-between

.meal-banner--success
  &::before
    background-color: $lima

.meal-banner--warning
  &::before
    background-color: $bright-sun
