= responsive_modal
  .modal-lg
    width: 100%

  .modal-dialog
    margin: 0

  .modal-content
    position: relative
    background-color: #fff
    background-clip: padding-box
    border: none
    border-radius: 0
    outline: 0

  .modal-header
    border: none
    background: #ffffff
    position: relative
    border-bottom: 1px solid $white-lilac
    -webkit-border-top-left-radius: 6px
    -webkit-border-top-right-radius: 6px
    -moz-border-radius-topleft: 6px
    -moz-border-radius-topright: 6px
    border-top-left-radius: 6px
    border-top-right-radius: 6px
    font-size: 18px
    color: $black-russian
    h4
      font-size: 18px
      margin: 0
    p
      font-size: 15px
      color: $grey-chateau
      margin: 10px 0 0
    button.close
      color: #fff
      opacity: 1
      border-radius: 50%
      width: 24px
      height: 24px
      border: 2px solid #fff
      display: inline-block
      background: #fff url('#{$images-url-path}/icon-modal-close.png') no-repeat 50% 50%
      background-size: 12px 12px
      text-indent: -9999px
      position: absolute
      right: 0
      top: 0
      margin-top: 15px
      margin-right: 10px
      z-index: 10
      @media (min-width: $screen-sm)
        margin-top: -11px
        margin-right: -11px
        background: $tyrian-purple url('#{$images-url-path}/icon-close.png') no-repeat 50% 50%

  .modal-title
    line-height: auto
    font-size: 18px
    float: left

  .modal-text
    float: right
    font-size: 14px
    margin: 5px 0 0 0

  .modal-backdrop
    z-index: 1040
    background-color: #370B25

  .modal-body
    .btn-primary
      background: $lima !important
      height: 40px !important
      font-size: 15px
      border: 1px solid $lima !important
      &:hover
        background: $yellow-green !important
        border: 1px solid $yellow-green !important
    textarea
      padding-top: 15px
      line-height: normal !important
    hr
      margin: 0
      border-top: 1px solid $white-lilac
    input[type="radio"], input[type="checkbox"]
      position: absolute
      margin-top: .25rem
      margin-left: -1.25rem

  /* modal mostly for menu

  .modal
    .media
      padding: 0
      margin: 0
    .media-body-content
      padding: 0
    .row
      margin-bottom: 10px
    .display-6 em
      font-family: 'geomanistlight_italic', sans-serif
      font-weight: normal
      color: $grey-chateau
    .checkbox, .radio
      margin-bottom: 6px
    .checkbox span, .radio span
      display: block
      padding: 2px 0 0 5px
    .checkbox label, .checkbox-inline, .radio label, .radio-inline
      padding-left: 1.25rem
      margin-bottom: 0
      font-weight: normal
      cursor: pointer
      font-size: 13px
      width: 65%
    .checkbox.unfilled_quantity .type-number-imitate
      display: none
    .checkbox .type-number-imitate
      float: right !important
    .text-variation
      display: inline-block !important
      color: $grey-chateau
      font-size: 11px
    .form-inline
      display: block
      clear: both
      margin-bottom: 10px
      .form-control
        height: 30px
        width: 60px
        padding-left: 10px
        line-height: 30px
        margin-right: 7px
        margin-bottom: 0
        float: left
      .c-select-qty
        padding: 0 30px 0 10px
        min-width: 70px
        max-width: 70px
        width: 25%
        line-height: 30px
        float: left
        display: table-cell
      label
        font-size: 13px
        display: table-cell
        float: left
        margin: 0 0 0 15px
        padding: 2px 15px 0 0

    textarea
      height: 90px
    .media .media-body-content
      float: left
      width: 100%
      padding-left: 0
    .media-heading
      float: none
      width: 100%
      font-size: 15px
      line-height: 21px
      color: $black-russian
      margin: 0
      .menu-price
        font-size: 15px
        font-family: "proxima-nova-soft",sans-serif
        font-weight: 500
        padding-left: 10px
        span
          font-size: 13px
          color: $grey-chateau
    .display-7 a
      font-size: 13px
    .informational-text
      h6
        font-family: 'geomanistbook', sans-serif
        font-size: 13px
        margin: 0
      .text-muted
        margin: 0
    .modal-header
      .container-fluid
        padding: 0 10px
      .row
        margin-bottom: 0
    .more-details
      font-size: 14px
      line-height: 20px
      p
        font-size: 14px
        line-height: 20px
      .more-details-desc
        color: $gun-powder
        //height: 60px
      .row .col-md-6 .row
        margin-bottom: 0
        margin-top: 10px
        &:last-child
          margin-bottom: 10px
    .more-details-headline
      font-size: 14px
      line-height: 20px
      font-weight: bold
      margin-bottom: 5px
    .more-details-included
      padding: 0 0 0 15px
      color: $gun-powder
      margin: 0
    .more-details-presentation, .more-details-dietary
      list-style: none
      padding: 0
      margin-bottom: 0
    .more-details-presentation li, .more-details-dietary li
      margin-bottom: 5px
      &:last-child
        margin-bottom: 0
    .more-details-presentation li img, .more-details-dietary li img
      display: table-cell
      float: left
      margin-right: 10px
      width: 16px
      height: auto
    .more-details-presentation li span
      display: table-cell
    .more-details-dietary li
      span
        display: table-cell
      a
        color: $gun-powder

    .type-number-imitate
      width: 84px
      height: 22px
      margin-bottom: 0
      input
        width: 40px
        height: 22px
        text-align: center
        border-radius: 0
        margin-left: 22px
        border-left: none
        padding: 0 5px
      .plus, .minus
        width: 22px
        height: 22px
        line-height: 20px !important
        background: #fff
        padding: 0 !important
      .plus
        border-radius: 0 3px 3px 0
      .minus
        border-radius: 3px 0 0 3px
        left: 0
    #special-instruction
      margin: 15px 0 10px 0
    .text-muted
      margin: 0

  .modal-md
    .row
      margin-bottom: 13px
    .form-control
      height: 36px
      line-height: 36px
    textarea.form-control
      height: 72px !important

  /* More Details Carousel

  .more-details-carousel
    .flickity-button-icon
      display: none

    .flickity-prev-next-button
      background: transparent
      border-radius: 0
      width: auto
      height: auto
      top: 50%
      margin-top: 0
      &.previous
        left: 0
        margin-left: 20px
      &.next
        right: 0
        margin-right: 20px
        &::before
          content: url('#{$images-url-path}/carousel-right-white.svg')
      &.previous::before
        content: url('#{$images-url-path}/carousel-left-white.svg')
    .flickity-page-dots
      display: none

  .more-details-carousel-main
    .carousel-cell
      width: 100%
      height: 228px
      .banner-img
        height: 228px
        background-position: center center !important
        background-repeat: no-repeat !important
        background-size: cover !important
        border-radius: 3px

  .more-details-carousel-nav
    background: #fff
    margin-top: 10px
    .carousel-cell
      width: 94px
      height: 71px
      margin-right: 10px
      opacity: 0.8
      &.is-nav-selected
        opacity: 1
      .banner-img
        height: 71px
        background-position: center center !important
        background-repeat: no-repeat !important
        background-size: cover !important
        border-radius: 3px

  .menu-item-modal-truncate-link
    margin-bottom: 0
    display: block

  .v2
    &.more-details-carousel
      .flickity-page-dots
        display: block
        bottom: 2rem

        .dot
          background: white
          opacity: 0.55
          &.is-selected
            opacity: 1
      .flickity-button-icon
        display: flex

      .flickity-prev-next-button
        width: 32px
        height: 32px
        background: white
        border-radius: 100%
        &.next::before
          content: ''
          display: none
        &.previous::before
          content: ''
          display: none

    &.more-details-carousel-main
      .carousel-cell
        width: 100%
        height: 510px
        .banner-img
          height: 510px
          background-position: center center !important
          background-repeat: no-repeat !important
          background-size: cover !important
      @media (max-width: $screen-sm)
        .carousel-cell
          width: 100%
          height: 216px
          .banner-img
            height: 216px
            border-radius: 0

  @media (min-width: $screen-sm)
    .modal
      .media-heading
        font-size: 17px
        .menu-price
          font-size: 17px
      .checkbox label, .checkbox-inline, .radio-inline
        width: 75%
      .radio label
        width: 100% !important
    .display-6
      font-size: 14px
    .modal-lg
      width: 90%
    .modal-md
      width: 575px
    .modal-content
    .modal-dialog
      margin: 2rem auto
    .modal-header
      padding: 1rem
    .modal-body .container-fluid
      padding: 0 2rem

  @media (min-width: $screen-md)
    .modal-lg
      width: 840px
