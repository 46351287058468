.main-header
  .nav-tabs
    padding: 0

    @media (min-width: $screen-xxs)
      padding: 0 1.5rem

.nav-tabs
  background-color: $white
  border-bottom: 4px solid transparent
  position: relative

  .nav-tabs__list
    list-style: none
    display: flex
    overflow-x: auto
    padding: 0
    margin: 0
    margin-bottom: -4px
    width: 100%

  .nav-tabs__list-secondary
    background-color: $white
    border-top: 1px solid $athens-grey
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 8px 16px rgba(20, 20, 21, 0.08)
    display: none
    list-style: none
    margin-top: 4px
    padding: 0
    position: absolute
    right: 0
    top: 100%
    z-index: 100

  .nav-tabs__list-more
    cursor: pointer
    min-width: 40px

  .nav-tabs__list-more--hidden
    display: none

  .nav-tabs__item
    padding: 10px
    font-family: $font-family-geomanistbook
    font-size: 17px
    flex: 1
    max-width: 150px
    text-align: center

    .nav-tabs__link
      color: $grey-chateau

  .nav-tabs__item-more
    padding: 0

  .nav-tabs__item--hidden
    display: none

  .nav-tabs__item--active
    border-bottom: 4px solid $lima

    .nav-tabs__link
      color: $black-russian

  .nav-tabs__link
    padding: 10px 5px
    white-space: nowrap

    &:hover
      text-decoration: none

  @media (min-width: $screen-xxs)
    .nav-tabs__list
      overflow-x: hidden

.nav-tabs--scrollable
  .nav-tabs__list
    overflow-x: auto !important

.nav-tabs--show-secondary
  .nav-tabs__list-secondary
    display: block
