.editing-indicator
  align-items: center
  background-color: $pink-flare
  border-radius: 4px
  display: flex
  font-family: $font-family-proximasoft
  font-size: 12px
  height: 32px
  justify-content: center
  margin-bottom: 1rem
  text-transform: uppercase
  width: 100%
  z-index: 10
