@import 'vars'
@import 'custom'
@import 'partials'
@import 'fonts'
@import 'type_number_imitate'
@import 'utilities'
@import 'badges'
@import 'loading'
@import 'bootstrap_overrides'
@import 'buttons'
@import 'glyphicons'
@import 'react_datepicker'
@import 'react_paginate'

.menuitem-form
  +responsive_modal

.btn-special-instruction
  font-size: 15px
  font-family: $font-family-proximasoft
  color: $lima
  background: url('#{$images-url-path}/special-instruction-icon.svg') 0 50% no-repeat
  background-size: 16px 16px
  padding-left: 26px

.display-6
  font-family: $font-family-geomanistbook
  font-size: 15px
  color: $black-russian
  border-bottom: 1px solid $white-lilac
  padding-bottom: 7px
  margin-bottom: 12px

.modal .btn-primary, .btn-primary
  &:disabled, &.disabled
    background: #9fb75a !important

@media (min-width: 576px)
  .pantry-program-jumbotron
    background-image: url('#{$images-url-path}/banner_pantry_program.jpg')
  .navbar-secondary .nav .nav-item .nav-link
    margin-right: 1.5rem

@media (max-width: 575.98px)
  .pantry-program-jumbotron
    background-image: url('#{$images-url-path}/banner_pantry_program_mobile.jpg')
  .fullscreen-modal
    .modal-dialog
      width: 100vw !important
      max-width: none !important
      margin: 0 !important
      height: 100% !important
      max-height: 100% !important
      .modal-content
        height: 100% !important
        max-height: 100% !important
