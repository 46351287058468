.v2
  &.more-details-carousel
    .flickity-page-dots
      display: block
      bottom: 2rem

      .dot
        background: white
        opacity: 0.55
        &.is-selected
          opacity: 1
  &.more-details-carousel-main
    .carousel-cell
      width: 100%
      height: 510px
      .banner-img
        height: 510px
        background-position: center center !important
        background-repeat: no-repeat !important
        background-size: cover !important
  &.more-details-carousel-aside
    .carousel-cell
      width: 100%
      height: 142px
      .banner-img
        height: 142px
        background-position: center center !important
        background-repeat: no-repeat !important
        background-size: cover !important
        border-radius: 8px
    .flickity-button
      top: 40%
      --tw-scale-x: 0.75
      --tw-scale-y: 0.75
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
      &.flickity-prev-next-button.previous
        transform-origin: top left
      &.flickity-prev-next-button.next
        transform-origin: top right
    .flickity-page-dots
      bottom: 1rem
    @media (max-width: $screen-sm)
      .carousel-cell
        width: 100%
        height: 216px
        .banner-img
          height: 216px
          border-radius: 0
