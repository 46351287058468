.mobile-nav
  background-color: $white
  bottom: 0
  box-shadow: inset 0px 1px 0px $gainsboro
  display: flex
  justify-content: space-around
  list-style: none
  margin: 0
  padding: 0
  padding-bottom: env(safe-area-inset-bottom)
  position: fixed
  width: 100vw
  z-index: 200

  .mobile-nav__item
    align-items: center
    cursor: pointer
    color: $grey-chateau
    display: flex
    flex-direction: column
    flex: 1
    font-family: $font-family-geomanistbook
    font-size: 11px
    justify-content: center
    line-height: 16px
    padding: 0.5rem
    text-align: center
    text-transform: uppercase

    &:hover,
    &:focus
      outline: none
      text-decoration: none

  .mobile-nav__item-active
    color: $lima

  @media (min-width: $screen-sm)
    display: none
