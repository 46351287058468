.card-cart
  background: white
  border-radius: 0.25rem
  box-shadow: 0px 4px 8px rgba(20, 20, 21, 0.04), 0px 2px 1px rgba(0, 0, 0, 0.08)

  .card-header
    background: white
    padding: .5rem 1.5rem

    h1, h2, h3, h4, h5, h6
      color: black

    .btn
      padding-left: 0
      padding-right: 0
      box-shadow: none

      &:hover
        text-decoration: none

  .cart-order-form
    padding: 1rem 1.5rem
    border-bottom: 1px solid #EAEAEB

    .alert
      padding: .5rem 1rem
      margin-bottom: .5rem

    .btn-location
      &:hover, &:focus
        text-decoration: none

  .card-thumbnail img
    width: 140px
    height: 84px
    transition: all .2s ease-in-out
    object-fit: fill

    &:hover
      transform: scale(1.25)
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.24), 0px 1px 2px rgba(0, 0, 0, 0.16)

  .card-body
    padding: .5rem 1.5rem

    &.order-total
      border-top: 1px solid #EAEAEB

    &.card-body-fixed
      max-height: 280px
      overflow-y: auto

    &.card-body-empty
      align-items: center
      color: $grey-chateau
      display: flex
      height: 56px
      justify-content: center

    &.card-body-grey
      background: #F4F5F5
      border-top: none
      padding: .5rem 1.5rem
      border-top-left-radius: 0 !important
      border-top-right-radius: 0 !important
      border-bottom-right-radius: 0.25rem
      border-bottom-left-radius: 0.25rem
      border-top-left-radius: 0.25rem
      border-bottom-left-radius: 0.25rem
      color: $grey-chateau

    h1, h2, h3, h4, h5, h6
      color: black

    .cart-order-list
      a
        text-decoration: none
        color: #515154

      .order-item
        padding: .5rem 0
        border-bottom: 1px solid #EAEAEB

        &:last-child
          border-bottom: none

        .order-item-title
          color: #141415

        a
          color: #7D9E1D

      .order-actions
        /*width: 110px;

        .custom-number
          border: 1px solid #DBDBDB
          box-shadow: 0 1px 0 #EAEAEB

          .input-number-decrement
            border-right: 1px solid #DBDBDB

          .input-number-increment
            border-left: 1px solid #DBDBDB

        .order-delete
          width: 24px
          height: 24px
          padding: 0
          background: white
          border: 1px solid transparent
          box-shadow: none

          &:hover
            border: 1px solid #DBDBDB
            box-shadow: 0 1px 0 #EAEAEB

    .cart-order-list__vendor-name
      display: flex
      flex-direction: row
      color: $grey-chateau
      padding-bottom: 10px

    .cart-order-list__vendor-name:after
      content: ""
      flex: 1
      border-bottom: 1px solid $athens-grey
      margin: auto
      margin-left: 10px

  .card-footer
    background: #F4F5F5
    border-top: none
    padding: 1rem 1.5rem
    border-top-left-radius: 0 !important
    border-top-right-radius: 0 !important
    border-bottom-right-radius: 0.25rem
    border-bottom-left-radius: 0.25rem
    border-top-left-radius: 0.25rem
    border-bottom-left-radius: 0.25rem

    .total-price
      font-size: 18px
      color: #141415
      font-weight: 500

    .btn-primary
      background: #FDDC33
      color: #141415
      font-weight: bold
      border-color: #F3D741
      border-bottom-color: #DDC02D
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%), #FDDC33
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08)

  .btn-location
    border: 1px solid #DBDBDB
    padding-left: 1rem
    padding-right: 1rem
    text-align: left
    color: #141415
