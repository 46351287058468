@font-face
  font-family: 'proximasoft'
  font-display: swap
  src: url("#{$fonts-url-path}/proxima-nova-soft-regular-webfont.eot")
  src: url("#{$fonts-url-path}/proxima-nova-soft-regular-webfont.eot?#iefix") format("embedded-opentype"), url("#{$fonts-url-path}/proxima-nova-soft-regular-webfont.woff") format("woff"), url("#{$fonts-url-path}/proxima-nova-soft-regular-webfont.ttf") format("truetype"), url("#{$fonts-url-path}/proxima-nova-soft-regular-webfont.svg") format("svg")
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'proximasoft'
  font-display: swap
  src: url("#{$fonts-url-path}/proxima-nova-soft-semibold-webfont.eot")
  src: url("#{$fonts-url-path}/proxima-nova-soft-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("#{$fonts-url-path}/proxima-nova-soft-semibold-webfont.woff") format("woff"), url("#{$fonts-url-path}/proxima-nova-soft-semibold-webfont.ttf") format("truetype"), url("#{$fonts-url-path}/proxima-nova-soft-semibold-webfont.svg") format("svg")
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'geomanistbook'
  font-display: swap
  src: url("../fonts/geomanist-book-webfont.eot")
  src: url("../fonts/geomanist-book-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/geomanist-book-webfont.woff2") format("woff2"), url("../fonts/geomanist-book-webfont.woff") format("woff"), url("../fonts/geomanist-book-webfont.ttf") format("truetype"), url("../fonts/geomanist-book-webfont.svg") format("svg")
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Glyphicons Halflings'
  src: url("../fonts/glyphicons-halflings-regular.eot")
  src: url("../fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("../fonts/glyphicons-halflings-regular.woff") format("woff"), url("../fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("../fonts/glyphicons-halflings-regular.svg") format("svg")
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'geomanistlight'
  font-display: swap
  src: url("../fonts/geomanist-light-webfont.eot")
  src: url("../fonts/geomanist-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/geomanist-light-webfont.woff2") format("woff2"), url("../fonts/geomanist-light-webfont.woff") format("woff"), url("../fonts/geomanist-light-webfont.ttf") format("truetype"), url("../fonts/geomanist-light-webfont.svg") format("svg")
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'geomanist_regularregular'
  font-display: swap
  src: url("../fonts/geomanist-regular-webfont.eot")
  src: url("../fonts/geomanist-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/geomanist-regular-webfont.woff2") format("woff2"), url("../fonts/geomanist-regular-webfont.woff") format("woff"), url("../fonts/geomanist-regular-webfont.ttf") format("truetype"), url("../fonts/geomanist-regular-webfont.svg") format("svg")
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'geomanistbook_italic'
  src: url("../fonts/geomanist-book-italic-webfont.eot")
  src: url("../fonts/geomanist-book-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/geomanist-book-italic-webfont.woff2") format("woff2"), url("../fonts/geomanist-book-italic-webfont.woff") format("woff"), url("../fonts/geomanist-book-italic-webfont.ttf") format("truetype"), url("../fonts/geomanist-book-italic-webfont.svg") format("svg")
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'geomanistlight_italic'
  font-display: swap
  src: url("../fonts/geomanist-light-italic-webfont.eot")
  src: url("../fonts/geomanist-light-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/geomanist-light-italic-webfont.woff2") format("woff2"), url("../fonts/geomanist-light-italic-webfont.woff") format("woff"), url("../fonts/geomanist-light-italic-webfont.ttf") format("truetype"), url("../fonts/geomanist-light-italic-webfont.svg") format("svg")
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'geomanistregular_italic'
  font-display: swap
  src: url("../fonts/geomanist-regular-italic-webfont.eot")
  src: url("../fonts/geomanist-regular-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/geomanist-regular-italic-webfont.woff2") format("woff2"), url("../fonts/geomanist-regular-italic-webfont.woff") format("woff"), url("../fonts/geomanist-regular-italic-webfont.ttf") format("truetype"), url("../fonts/geomanist-regular-italic-webfont.svg") format("svg")
  font-weight: normal
  font-style: normal


.font-600
  font-weight: 600


