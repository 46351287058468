.guided-info
  color: $white
  padding: 1rem
  max-width: calc(100vw - 3rem)

  .guided-info__title
    margin-bottom: 1rem

    .guided-info__title-text
      font-family: $font-family-geomanistbook
      font-size: 1rem
      line-height: 1.5rem

  .guided-info__content
    margin-bottom: 1rem
    font-family: $font-family-proximasoft
    font-size: 0.875rem
    line-height: 1.5

  .guided-info__actions
    .btn
      font-weight: 600

  .guided-info__title-badge
    background-color: $broom
    border-radius: 4px
    color: $black
    padding: 0.3rem 0.5rem
    margin-right: 0.5rem

  @media (min-width: $screen-xs)
    max-width: 315px
