ul.react-paginate
    display: flex
    justify-content: center
    align-items: center
    list-style-type: none
    gap: 0.5rem

ul.react-paginate li a
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    line-height: 2rem
    width: 2rem
    height: 2rem
    border-radius: 4px
    border: 1px solid var(--gray-500, #95989D)
    background: var(--white, #FFF)

ul.react-paginate li.previous a,
ul.react-paginate li.next a,
ul.react-paginate li.break a
    border-color: transparent

ul.react-paginate li.selected a
    background-color: var(--gray-600, #515154)
    border-color: transparent
    color: white !important

ul.react-paginate li.disabled a
    color: grey

ul.react-paginate li.disable,
ul.react-paginate li.disabled a
    cursor: default
