.meal-plans-top-background
  background-color: $lima
  width: 100%
  height: 200px
  position: absolute
  top: 0
  z-index: -1

.meal-plans-container
  max-width: 64rem
  padding: 0

.meal-plans-title
  color: $white
  font-family: $font-family-geomanistbook
  font-size: 30px
  font-weight: 400
  line-height: 33px
  margin: 5rem 0 1.5rem
  padding: 0 1rem

.meal-plans-selections
  align-items: stretch
  display: flex
  flex-wrap: wrap
  justify-content: start
  padding: 0 1rem

.meal-plans-item
  background-color: $white
  border-radius: 4px
  box-shadow: 0px 2px 4px 0px rgba(20, 20, 21, 0.1)
  flex: 0 0 100%
  list-style: none
  margin-bottom: 1rem

  .meal-plans-item__container
    height: 100%
    padding: 1.5rem

  .meal-plans-item__icon
    margin-bottom: 20px

  .meal-plans-item__text
    color: $gun-powder
    font-family: $font-family-geomanistbook
    font-size: 17px
    font-weight: 400
    line-height: 21px

@media (min-width: $screen-sm)
  .meal-plans-top-background
    height: 270px

@media (min-width: 680px)
  .meal-plans-selections
    margin-right: -0.8rem

  .meal-plans-item
    flex: 0 0 45%
    margin-bottom: 1.2rem
    margin-right: 1.2rem

    .meal-plans-item__container
      padding: 1.5rem 2rem

@media (min-width: 64rem)
  .meal-plans-selections
    margin-right: -1.2rem

  .meal-plans-item
    flex: 0 0 31.3%
    margin-bottom: 1.2rem
    margin-right: 1.2rem
