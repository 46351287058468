// The paths are relative to this file!
$fonts-url-path: '../fonts'
$images-url-path: '../images'

$athens-grey: #d5d6d8
$bizarre: #eddcda
$black: #000000
$black-marlin: #3a3a3e
$black-russian: #141415 // 20  20  21
$bright-sun: #fddc33
$broom: #dce813
$chantilly: #edc5d8
$disco: #8f1775
$feta: #e6e9dd // 230 233 221
$gainsboro: #dbdbdb
$ghost: #bfc1c4 // 191 193 196
$grey-chateau: #95989d
$gun-powder: #515154
$heather: #b0bfc7
$ivory: #fcfff3
$lima: #7d9e1d
$pink-flare: #e1c2c2
$rock-spray: #a43b2b
$tyrian-purple: #1e0414 // 30   3  19
$white-lilac: #eaeaeb // 234 234 235
$yellow-green: #9abc37
$zircon: #f4f5f5
$white: #ffffff

$screen-xxs: 498px
$screen-xs: 34em
$screen-presm: 545px
$screen-sm: 48em
$screen-md: 62em
$screen-lg: 74.9em

$font-family-geomanistbook: 'geomanistbook', sans-serif
$font-family-proximasoft: 'proximasoft', sans-serif
