.__floater
  padding: 1rem !important

.__floater__body
  background-color: $disco
  border-radius: 6px

.__floater__arrow
  svg
    polygon
      fill: $disco
