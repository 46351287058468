.responsive-meal-cart
  .responsive-meal-cart__overlay
    position: fixed
    overflow: hidden
    margin-left: -100%
    top: 0
    left: 0
    right: 0
    transition: 0.2s
    width: 100%
    z-index: 1001

  .responsive-meal-cart__overlay--open
    margin-left: 0%

  .responsive-meal-cart__content
    background-color: $white
    height: 100vh
    overflow-y: scroll

  .responsive-meal-cart__content-header
    color: $black
    padding: 0.5rem 1.5rem

  .responsive-meal-cart__actions
    position: fixed
    bottom: 0
    background: linear-gradient(180deg, rgba(234, 234, 235, 0) 0%, $white-lilac 100%)
    left: 0
    padding: 0 1.5rem
    right: 0
    z-index: 99
    width: 100%

  .responsive-meal-cart__actions-wrapper
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    margin-right: -0.5rem

  .responsive-meal-cart__button
    margin-bottom: 1rem
    margin-right: 0.5rem
    font-size: 0.875rem
    font-weight: 600
    font-family: $font-family-proximasoft
    height: 44px

  .card-cart
    box-shadow: none

  .responsive-meal-cart__spacer
    height: 4rem

    &:before
      content: ''

  @media (min-width: $screen-xxs)
    .responsive-meal-cart__spacer
      display: none

  @media (min-width: $screen-md)
    .responsive-meal-cart__overlay
      position: unset
      margin: 0 !important

    .responsive-meal-cart__content
      height: unset
      overflow-y: unset

    .user-cart
     margin-bottom: 1.5rem
