.login-container
  background-color: $white
  height: 100vh

  @media (min-width: $screen-xs)
    background-color: $white-lilac
    height: unset

.login-card
  background-color: $white
  padding: 24px 10px
  text-align: center
  width: 100%

  h5
    font-family: $font-family-geomanistbook
    font-weight: 400
    font-size: 20px
    line-height: 22px
    margin-bottom: 2rem
  
  .login-card_hint
    margin-bottom: 1rem

  .login-card__form-group
    margin-bottom: 1rem

    &:last-child
      margin-bottom: 2rem

  @media (min-width: $screen-xs)
    border-radius: 4px
    box-shadow: 0px 2px 4px rgba(20, 20, 21, 0.1)
    padding: 48px 60px
    text-align: left
    width: 555px

.login-logo
  display: flex
  justify-content: center
  margin: 80px 0 32px
